import React, { Component } from "react";
import FullPageLoader from "../Shared/Loaders/FullPageLoader";
import { setApiKey, API_KEY_NAME, CURRENT_USER, invalidateApiToken } from "../../api";
import Cookies from "cookies-js";

class LogoutPage extends Component {
    componentDidMount() {
        invalidateApiToken().catch(() => {
            // Token is already invalid, so remove it from local storage
            setApiKey(null);
            Cookies.expire(API_KEY_NAME);

            window.localStorage.removeItem(CURRENT_USER);
            window.location.replace("/login");
        });
    }

    render() {
        return (
            <div className="mt-16">
                <FullPageLoader message="Logging out..." />
            </div>
        );
    }
}

export default LogoutPage;
